/**
 * @fileoverview gRPC-Web generated client stub for doc_type
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/doc_type/doc_type.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_doc_type_doc_type_pb from '../../protobuf/doc_type/doc_type_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class DocTypeServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/doc_type.DocTypeService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_doc_type_doc_type_pb.CreateDocTypeRequest,
    protobuf_model_merged_pb.DocTypeResponse,
    (request: protobuf_doc_type_doc_type_pb.CreateDocTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.DocTypeResponse.deserializeBinary
  );

  create(
    request: protobuf_doc_type_doc_type_pb.CreateDocTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.DocTypeResponse>;

  create(
    request: protobuf_doc_type_doc_type_pb.CreateDocTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DocTypeResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.DocTypeResponse>;

  create(
    request: protobuf_doc_type_doc_type_pb.CreateDocTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DocTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/doc_type.DocTypeService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/doc_type.DocTypeService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/doc_type.DocTypeService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_doc_type_doc_type_pb.UpdateDocTypeRequest,
    protobuf_model_merged_pb.DocTypeResponse,
    (request: protobuf_doc_type_doc_type_pb.UpdateDocTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.DocTypeResponse.deserializeBinary
  );

  update(
    request: protobuf_doc_type_doc_type_pb.UpdateDocTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.DocTypeResponse>;

  update(
    request: protobuf_doc_type_doc_type_pb.UpdateDocTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DocTypeResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.DocTypeResponse>;

  update(
    request: protobuf_doc_type_doc_type_pb.UpdateDocTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.DocTypeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/doc_type.DocTypeService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/doc_type.DocTypeService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/doc_type.DocTypeService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_doc_type_doc_type_pb.GetDocTypesRequest,
    protobuf_doc_type_doc_type_pb.GetDocTypesResponse,
    (request: protobuf_doc_type_doc_type_pb.GetDocTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_doc_type_doc_type_pb.GetDocTypesResponse.deserializeBinary
  );

  get(
    request: protobuf_doc_type_doc_type_pb.GetDocTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_doc_type_doc_type_pb.GetDocTypesResponse>;

  get(
    request: protobuf_doc_type_doc_type_pb.GetDocTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_doc_type_doc_type_pb.GetDocTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_doc_type_doc_type_pb.GetDocTypesResponse>;

  get(
    request: protobuf_doc_type_doc_type_pb.GetDocTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_doc_type_doc_type_pb.GetDocTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/doc_type.DocTypeService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/doc_type.DocTypeService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/doc_type.DocTypeService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_doc_type_doc_type_pb.DeleteDocTypesRequest,
    protobuf_doc_type_doc_type_pb.DeleteDocTypesResponse,
    (request: protobuf_doc_type_doc_type_pb.DeleteDocTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_doc_type_doc_type_pb.DeleteDocTypesResponse.deserializeBinary
  );

  delete(
    request: protobuf_doc_type_doc_type_pb.DeleteDocTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_doc_type_doc_type_pb.DeleteDocTypesResponse>;

  delete(
    request: protobuf_doc_type_doc_type_pb.DeleteDocTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_doc_type_doc_type_pb.DeleteDocTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_doc_type_doc_type_pb.DeleteDocTypesResponse>;

  delete(
    request: protobuf_doc_type_doc_type_pb.DeleteDocTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_doc_type_doc_type_pb.DeleteDocTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/doc_type.DocTypeService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/doc_type.DocTypeService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/doc_type.DocTypeService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_doc_type_doc_type_pb.ListDocTypesRequest,
    protobuf_doc_type_doc_type_pb.ListDocTypesResponse,
    (request: protobuf_doc_type_doc_type_pb.ListDocTypesRequest) => {
      return request.serializeBinary();
    },
    protobuf_doc_type_doc_type_pb.ListDocTypesResponse.deserializeBinary
  );

  list(
    request: protobuf_doc_type_doc_type_pb.ListDocTypesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_doc_type_doc_type_pb.ListDocTypesResponse>;

  list(
    request: protobuf_doc_type_doc_type_pb.ListDocTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_doc_type_doc_type_pb.ListDocTypesResponse) => void): grpcWeb.ClientReadableStream<protobuf_doc_type_doc_type_pb.ListDocTypesResponse>;

  list(
    request: protobuf_doc_type_doc_type_pb.ListDocTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_doc_type_doc_type_pb.ListDocTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/doc_type.DocTypeService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/doc_type.DocTypeService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

}

